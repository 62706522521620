<template>
    <div class="caseStudySlider__wrapper">
        <CurrentSlide />
        <MainSlider />
    </div>
</template>

<script>
import CurrentSlide from './CurrentSlide'
import MainSlider from './MainSlider'

export default {
    components: {
        CurrentSlide,
        MainSlider
    }
}
</script>