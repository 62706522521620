import Vue from 'vue'
import store from './store'
import Layout from './components/Layout'
import { polyfill } from 'es6-promise'
polyfill()

const caseStudySliderEl = document.querySelector('.js-caseStudySlider')

if (caseStudySliderEl) {
    new Vue({
        el: caseStudySliderEl,
        store,
        components: {
            Layout
        }
    })
}