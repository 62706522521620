const groupDropdowns = [...document.querySelectorAll('.js-groupedTabs')]

if (groupDropdowns.length) {
    groupDropdowns.forEach(groupDropdown => {
        const tabItems = [...groupDropdown.querySelectorAll('.js-tabItem')]
        const tabPanels = [...groupDropdown.querySelectorAll('.js-tabPanel')]
        const focusables = [...groupDropdown.querySelectorAll('.js-dropdownGroupLink')]

        tabItems.forEach(tabItem => {
            tabItem.addEventListener('mouseover', () => {
                const tabId = tabItem.dataset.panel
                const associatedTabPanel = groupDropdown.querySelector(`.js-tabPanel[data-panel='${tabId}']`)

                tabPanels.forEach(tabPanel => tabPanel.classList.remove('-active'))
                tabItems.forEach(tabItem => tabItem.classList.remove('-active'))
                associatedTabPanel.classList.add('-active')
                tabItem.classList.add('-active')
            })  
        })

        tabItems.forEach(tabItem => {
            tabItem.addEventListener('keydown', e => {
                if (e.key === 'Enter' || e.key === ' ') {
                    const tabId = tabItem.dataset.panel
                    const associatedTabPanel = groupDropdown.querySelector(`.js-tabPanel[data-panel='${tabId}']`)
                    
                    const associatedTabPanelFocusables = [...associatedTabPanel.querySelectorAll('.js-dropdownGroupLink')]
                    const [firstFocusable] = associatedTabPanelFocusables

                    focusables.forEach(focusable => focusable.tabIndex = '-1')
                    associatedTabPanelFocusables.forEach(associatedTabPanelFocusable => associatedTabPanelFocusable.tabIndex = '0')
                    firstFocusable.focus()

                    tabPanels.forEach(tabPanel => tabPanel.classList.remove('-active'))
                    tabItems.forEach(tabItem => tabItem.classList.remove('-active'))
                    associatedTabPanel.classList.add('-active')
                    tabItem.classList.add('-active')
                }
            })
        })
    })
}