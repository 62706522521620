<template>
    <div class="caseStudySlider__body">
        <h2 class="caseStudySlider__slideTitle">
            {{ currentSlide.title }}
        </h2>
        <a :href="currentSlide.ctaUrl" class="button caseStudySlider__button">
            {{ currentSlide.ctaText }}
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import smoothReflow from 'vue-smooth-reflow'

export default {

    mixins: [smoothReflow],

    computed: {
        ...mapGetters(['currentSlide'])
    },

    mounted () {
        this.$smoothReflow({
            property: ['height', 'width'],
            transition: 'height 250ms cubic-bezier(0.16, 1, 0.3, 1), width 250ms cubic-bezier(0.16, 1, 0.3, 1)'
        })
    }

}
</script>